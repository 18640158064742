export const LOCAL_ENV = 'LOCAL_ENV'
export const DEMO_ENV = 'DEMO_ENV'
export const PRODUCTION_ENV = 'PRODUCTION_ENV'

const ENV = env => ({
    production: PRODUCTION_ENV,
    demo: DEMO_ENV,
    local: LOCAL_ENV,
})[env]

export const ENVIRONMENT = ENV(process.env.NODE_ENV)
