import { ABBR } from '../constants/constantsValue';
import { AUTOMEKANO_HEADLESS_URL, AUTOMEKANO_SITE_URL } from '../constants/constantsApp';

export const getInitials = function(name) {
  const names = name.split(' ')
  let initials = names[0].substring(0, 1).toUpperCase()
  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase()
  }
  return initials
}

export const replaceSpecialCharacters = (function() {
  const translateRe = /[áéíóúñÁÉÍÓÚÑ.]/g
  const translate = {
    á: 'a',
    é: 'e',
    í: 'i',
    ó: 'o',
    ú: 'u',
    ñ: 'n',
    '.': '',
    Á: 'A',
    É: 'E',
    Í: 'I',
    Ó: 'O',
    Ú: 'U',
    Ñ: 'N', // probably more to come
  }
  return function(s) {
    return s.replace(translateRe, match => {
      return translate[match]
    })
  }
})()

export const s4 = () => {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1)
}

export const flattenArray = arr =>
  Array.isArray(arr) ? [].concat(...arr.map(flattenArray)) : [arr]

export const ddmmyyyyToDate = (date: string) => {
  const parts: number[] = date.split('-').map(Number)
  return new Date(parts[2], parts[1] - 1, parts[0])
}

/**
 * Escape RegExp special characters
 * @param {string} str
 * @returns {string}
 */
export const escapeRegExp = (str: string) =>
  str.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&')

export const getDateWithFormat = () => {
  const d = new Date()
  const date = `${d.getFullYear()}-${`0${d.getMonth() + 1}`.slice(
    -2
  )}-${`0${d.getDate()}`.slice(-2)}`
  const hours = `${`0${d.getHours()}`.slice(-2)}`
  const minutes = `${`0${d.getMinutes()}`.slice(-2)}`
  const seconds = `${`0${d.getSeconds()}`.slice(-2)}`
  const time = `${hours}:${minutes}:${seconds}`
  return `${date}T${time}Z`
}

export const getDate = () => {
  const d = new Date()
  return `${d.getFullYear()}-${`0${d.getMonth() + 1}`.slice(
    -2
  )}-${`0${d.getDate()}`.slice(-2)}`
}

export const deleteSpacesAndCommas = (string: string) => {
  return string
    .split(',')
    .join('')
    .split(' ')
    .join('-')
}

export const getLocalDate = () => {
  const date = new Date()
  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    timeZone: 'UTC',
    timeZoneName: 'short',
  }
  return date.toLocaleDateString('es-ES', options)
}

export const AbbrApply = (text: string) => {
  const textPart = text.split(' ')
  const textFind = textPart.find(part => ABBR.some(abbr => abbr.text.toLowerCase() === part.toLowerCase()))
  if (!!textFind) {
    const regex = new RegExp(textFind)
    const abbr = ABBR.find(abbr => abbr.text.toLowerCase() === textFind.toLowerCase())
    return text.replace(regex, `<abbr title="${abbr?.text}">${abbr?.short}</abbr>`)
  }
  return text;
}

export const replaceDomain = (text: string) =>
  text.replaceAll(AUTOMEKANO_HEADLESS_URL, AUTOMEKANO_SITE_URL)

export const replaceString = (text: string, search = '', replace = '') => {
  const regex = new RegExp(search, 'g')
  return text.replaceAll(regex, replace)
}
