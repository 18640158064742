exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-concesionarios-tsx": () => import("./../../../src/pages/concesionarios.tsx" /* webpackChunkName: "component---src-pages-concesionarios-tsx" */),
  "component---src-pages-contacto-tsx": () => import("./../../../src/pages/contacto.tsx" /* webpackChunkName: "component---src-pages-contacto-tsx" */),
  "component---src-pages-cotizar-tsx": () => import("./../../../src/pages/cotizar.tsx" /* webpackChunkName: "component---src-pages-cotizar-tsx" */),
  "component---src-pages-exonerados-tsx": () => import("./../../../src/pages/exonerados.tsx" /* webpackChunkName: "component---src-pages-exonerados-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-noticias-tsx": () => import("./../../../src/pages/noticias.tsx" /* webpackChunkName: "component---src-pages-noticias-tsx" */),
  "component---src-pages-ofertas-tsx": () => import("./../../../src/pages/ofertas.tsx" /* webpackChunkName: "component---src-pages-ofertas-tsx" */),
  "component---src-pages-repuestos-tsx": () => import("./../../../src/pages/repuestos.tsx" /* webpackChunkName: "component---src-pages-repuestos-tsx" */),
  "component---src-pages-talleres-tsx": () => import("./../../../src/pages/talleres.tsx" /* webpackChunkName: "component---src-pages-talleres-tsx" */),
  "component---src-templates-detail-model-tsx": () => import("./../../../src/templates/DetailModel.tsx" /* webpackChunkName: "component---src-templates-detail-model-tsx" */),
  "component---src-templates-detail-news-blog-tsx": () => import("./../../../src/templates/DetailNewsBlog.tsx" /* webpackChunkName: "component---src-templates-detail-news-blog-tsx" */),
  "component---src-templates-detail-offer-tsx": () => import("./../../../src/templates/DetailOffer.tsx" /* webpackChunkName: "component---src-templates-detail-offer-tsx" */),
  "component---src-templates-page-generic-tsx": () => import("./../../../src/templates/PageGeneric.tsx" /* webpackChunkName: "component---src-templates-page-generic-tsx" */),
  "component---src-templates-page-with-history-tsx": () => import("./../../../src/templates/PageWithHistory.tsx" /* webpackChunkName: "component---src-templates-page-with-history-tsx" */),
  "component---src-templates-subtype-model-tsx": () => import("./../../../src/templates/SubtypeModel.tsx" /* webpackChunkName: "component---src-templates-subtype-model-tsx" */)
}

