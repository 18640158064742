import { LABEL_ERROR } from './constantsLabel'
import { ElementForm, ElementFormDealer, FormQuotes } from '../types/components'

export const EMPTY_STRING = ''
export const EMPTY_ARRAY = []
export const EMPTY_NAME_URL = {
  name: '',
  url: '',
}

export const DEFAULT_SELECT = (value: string = '') => ({
  value,
  id: '',
  label: '',
})

export const EMPTY_FIELD = (
  error: any = LABEL_ERROR.ERROR_REQUIRED
): ElementForm => ({
  id: '',
  value: '',
  error: error,
  payload: '',
})

export const EMPTY_FIELD_DEALER = (
  error: any = LABEL_ERROR.ERROR_REQUIRED
): ElementFormDealer => ({
  id: '',
  value: '',
  type: '',
  city: '',
  error: error,
  externalEmail: '',
})

export const EMPTY_FIELD_PILOT_CODE = (
  error: any = LABEL_ERROR.ERROR_REQUIRED
): ElementForm => ({
  id: '',
  codigoPilot: '',
  value: '',
  error: error,
})

export const EMPTY_FIELD_ZEUS_CODE = (
  error: any = LABEL_ERROR.ERROR_REQUIRED
): ElementForm => ({
  id: '',
  codigoZeus: '',
  value: '',
  error: error,
})

export const FORM_CONTACT_DEFAULT = {
  name: EMPTY_FIELD(),
  email: EMPTY_FIELD(),
  phone: EMPTY_FIELD(),
  comments: EMPTY_FIELD(),
}

export const FORM_OFFER_DEFAULT = {
  title: '',
  brand: EMPTY_FIELD(),
  model: EMPTY_FIELD(),
  version: EMPTY_FIELD_ZEUS_CODE(),
  versionPilot: EMPTY_FIELD_PILOT_CODE(),
  name: EMPTY_FIELD(),
  email: EMPTY_FIELD(),
  phone: EMPTY_FIELD(),
  dealer: EMPTY_FIELD_DEALER(),
  comments: EMPTY_FIELD(null),
  identification: EMPTY_FIELD(),
  type: EMPTY_FIELD(),
}

export const FORM_DATA_SHEET_DEFAULT = (
  brand = EMPTY_FIELD(),
  model = EMPTY_FIELD(),
  urlDataSheet = EMPTY_FIELD()
) => ({
  brand,
  model,
  urlDataSheet,
  name: EMPTY_FIELD(),
  email: EMPTY_FIELD(),
  phone: EMPTY_FIELD(),
  dealer: EMPTY_FIELD_DEALER(),
  identification: EMPTY_FIELD(),
})

export const FORM_QUOTE_DEFAULT = (
  brand = EMPTY_FIELD(),
  model = EMPTY_FIELD(),
  version = EMPTY_FIELD(),
  price = EMPTY_FIELD(),
  downPayment = EMPTY_FIELD(null),
  exonerated = EMPTY_FIELD(null),
  dealer = EMPTY_FIELD()
) => ({
  brand,
  model,
  version,
  price,
  downPayment,
  dealer,
  exonerated,
  versionAmbacar: EMPTY_FIELD_ZEUS_CODE(),
  versionPilot: EMPTY_FIELD_PILOT_CODE(),
  identification: EMPTY_FIELD(),
  name: EMPTY_FIELD(),
  email: EMPTY_FIELD(),
  phone: EMPTY_FIELD(),
  date: EMPTY_FIELD(),
  type: EMPTY_FIELD(),
} as FormQuotes)

export const ABBR = [
  { text: 'Capacidad', short: 'Cap.' },
  { text: 'Camiones', short: 'Cam.' }
]
