export const validateDocument = (identification: string) => {
  const number = identification
  let suma = 0
  let residuo = 0
  let pri = false
  let pub = false
  let nat = false
  const numberProvinces = 24
  let modulo = 11
  let p1
  let p2
  let p3
  let p4
  let p5
  let p6
  let p7
  let p8
  let p9
  let d1
  let d2
  let d3
  let d4
  let d5
  let d6
  let d7
  let d8
  let d9
  let d10

  /* Verifica que no contenga letras */
  let ok = 1
  for (let i = 0; i < number.length && ok === 1; i = i + 1) {
    const n = parseInt(number.charAt(i), 10)
    if (isNaN(n)) {
      ok = 0
    }
  }
  if (ok === 0) {
    return {
      verification: false,
      message: 'No puede ingresar caracteres en el número',
    }
  }

  if (number.length < 10) {
    return {
      verification: false,
      message: 'El número de cédula ingresado no es válido',
    }
  }

  /* Los primeros dos digitos corresponden al codigo de la provincia */
  const province = parseInt(number.substr(0, 2), 10)
  if (province < 1 || province > numberProvinces) {
    return {
      verification: false,
      message: 'El código de la provincia (dos primeros dígitos) es inválido',
    }
  }

  /* Aqui almacenamos los digitos de la cedula en variables. */
  d1 = number.substr(0, 1)
  d2 = number.substr(1, 1)
  d3 = number.substr(2, 1)
  d4 = number.substr(3, 1)
  d5 = number.substr(4, 1)
  d6 = number.substr(5, 1)
  d7 = number.substr(6, 1)
  d8 = number.substr(7, 1)
  d9 = number.substr(8, 1)
  d10 = number.substr(9, 1)

  d1 = parseInt(d1, 10)
  d2 = parseInt(d2, 10)
  d3 = parseInt(d3, 10)
  d4 = parseInt(d4, 10)
  d5 = parseInt(d5, 10)
  d6 = parseInt(d6, 10)
  d7 = parseInt(d7, 10)
  d8 = parseInt(d8, 10)
  d9 = parseInt(d9, 10)
  d10 = parseInt(d10, 10)

  /* El tercer digito es: */
  /* 9 sociedades privadas y extranjeros   */
  /* 6 sociedades publicas */
  /* Menor a 6 personas naturales */

  if (d3 === 7 || d3 === 8) {
    return {
      verification: false,
      message: 'El tercer dígito ingresado es inválido',
    }
  }

  /* Solo para personas naturales */
  if (d3 < 6) {
    nat = true
    p1 = d1 * 2
    if (p1 >= 10) {
      p1 -= 9
    }
    p2 = d2 * 1
    if (p2 >= 10) {
      p2 -= 9
    }
    p3 = d3 * 2
    if (p3 >= 10) {
      p3 -= 9
    }
    p4 = d4 * 1
    if (p4 >= 10) {
      p4 -= 9
    }
    p5 = d5 * 2
    if (p5 >= 10) {
      p5 -= 9
    }
    p6 = d6 * 1
    if (p6 >= 10) {
      p6 -= 9
    }
    p7 = d7 * 2
    if (p7 >= 10) {
      p7 -= 9
    }
    p8 = d8 * 1
    if (p8 >= 10) {
      p8 -= 9
    }
    p9 = d9 * 2
    if (p9 >= 10) {
      p9 -= 9
    }
    modulo = 10
  } else if (d3 === 6) {
    pub = true
    p1 = d1 * 3
    p2 = d2 * 2
    p3 = d3 * 7
    p4 = d4 * 6
    p5 = d5 * 5
    p6 = d6 * 4
    p7 = d7 * 3
    p8 = d8 * 2
    p9 = 0
  } else if (d3 === 9) {
    pri = true
    p1 = d1 * 4
    p2 = d2 * 3
    p3 = d3 * 2
    p4 = d4 * 7
    p5 = d5 * 6
    p6 = d6 * 5
    p7 = d7 * 4
    p8 = d8 * 3
    p9 = d9 * 2
  }

  suma = p1 + p2 + p3 + p4 + p5 + p6 + p7 + p8 + p9
  residuo = suma % modulo

  /* Si residuo=0, dig.ver.=0, caso contrario 10 - residuo*/
  const checkDigit = residuo === 0 ? 0 : modulo - residuo

  /* ahora comparamos el elemento de la posicion 10 con el dig. ver.*/
  if (pub === true) {
    if (checkDigit !== d9) {
      return {
        verification: false,
        message: 'El ruc de la empresa del sector público es incorrecto.',
      }
    }
    /* El ruc de las empresas del sector publico terminan con 0001*/
    if (number.substr(9, 4) !== '0001') {
      return {
        verification: false,
        message:
          'El ruc de la empresa del sector público debe terminar con 0001',
      }
    }
  } else if (pri === true) {
    if (checkDigit !== d10) {
      return {
        verification: false,
        message: 'El ruc de la empresa del sector privado es incorrecto.',
      }
    }
    if (number.substr(10, 3) !== '001') {
      return {
        verification: false,
        message:
          'El ruc de la empresa del sector privado debe terminar con 001',
      }
    }
  } else if (nat === true) {
    if (checkDigit !== d10) {
      return {
        verification: false,
        message: 'El número de cédula de la persona natural es incorrecto.',
      }
    }
    if (number.length > 10 && number.substr(10, 3) !== '001') {
      return {
        verification: false,
        message: 'El ruc de la persona natural debe terminar con 001',
      }
    }
  }
  return {
    verification: true,
    message: '',
  }
}
