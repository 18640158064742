module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://headless.automekano.com/graphql","schema":{"requestConcurrency":1,"timeout":90000,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp","perPage":100,"previewRequestConcurrency":5},"html":{"createStaticFiles":false,"useGatsbyImage":true,"imageMaxWidth":null,"fallbackImageMaxWidth":1024,"imageQuality":70,"generateWebpImages":true,"generateAvifImages":false,"placeholderType":"dominantColor"},"type":{"MediaItem":{"localFile":{"requestConcurrency":1,"maxFileSizeBytes":100000000,"excludeByMimeTypes":["application/pdf"]},"placeholderSizeName":"gatsby-image-placeholder","createFileNodes":true,"lazyNodes":false},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"excludedRoutes":["**/*.pdf"],"verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Automekano","short_name":"Automekano","description":"Distribuidor exclusivo en el Ecuador de las marcas: JCB, UDTRUCKS, LEEBOY ROSCO, UDT, NISSAN DIESEL, SOUEAST, SOUEAST DX7","lang":"es","display":"standalone","icon":"src/images/automekano-icon.jpg","start_url":"/","background_color":"#FEF4CB","theme_color":"#FDFDFE","related_applications":[],"prefer_related_applications":false,"crossOrigin":"anonymous","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"1ee3719e911fb59793e96148a3e1c553"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5H8TQQX","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
