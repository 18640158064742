import Cabezales from '../images/cabezales.png';
import Camiones from '../images/camiones.png';
import Camioneta from '../images/camioneta.png';
import PickupGreatWall from '../images/pickup-great-wall.png';
import SuvSoueast from '../images/suv-soueast.png';
import SedanSoueast from '../images/sedan-soueast.png';
import SuvDfsk from '../images/suv-dfsk.png';
import VanDfsk from '../images/van-dfsk.png';
import MiniVanDfsk from '../images/mini-van-dfsk.png';
import VanKingLong from '../images/van-king-long.png';
import TruckUdTrucks from '../images/truck-ud-trucks.png';
import DumpTruckUdTrucks from '../images/dump-truck-ud-trucks.png';
import HeadsUdTrucks from '../images/heads-ud-trucks.png';
import BackhoesJcb from '../images/backhoes-jcb.png';
import ExcavatorsJcb from '../images/excavators-jcb.png';
import CompactorsJcb from '../images/compactors-jcb.png';
import Compactadores from '../images/compactadores.png';
import Electrico from '../images/electrico.png';
import ElectricDfsk from '../images/electric-dfsk.png';
import Excavadoras from '../images/excavadoras.png';
import Furgonetas from '../images/furgonetas.png';
import FurgonetasEscolares from '../images/furgonetas-escolares.png';
import MiniCargadores from '../images/mini-cargadores.png';
import Retroexcavadoras from '../images/retroexcavadoras.png';
import Sedan from '../images/sedan.png';
import Suv from '../images/suv.png';
import Volquetas from '../images/volquetas.png';
import MiniCamioneta from '../images/mini-camioneta.png';
import Montacargas from '../images/montacargas.png';
import * as constants from 'node:constants'

const LABEL_ERROR = {
  ERROR_REQUIRED: 'Campo obligatorio',
  ERROR_INVALID_EMAIL: 'Email inválido',
  ERROR_MIN_LENGTH: (length: number) => `Mínimo ${length} caracteres`,
  COMPLETE_NAME: 'Nombres y Apellidos',
  ERROR_SERVER: 'Error de Servidor',
}

const LABEL_INPUT = {
  IDENTIFICATION: 'Cedula',
  NAME: 'Nombre Completo',
  EMAIL: 'Correo Electrónico',
  PHONE: 'Teléfono',
  DEALER: 'Concesionario',
  COMMENTS: 'Comentarios',
  DATE: 'Fecha estimada de compra (meses)',
  PRICE: 'Precio',
  REFERENCE_PRICE: 'Precio desde',
  DOWN_PAYMENT: 'Entrada de',
  MESSAGE_QUOTE:
    '<p>' +
    'Mediante el presente registro, el titular de los datos personales expresa su consentimiento previo, inequívoco e informado para el uso de sus datos con la empresa Automekano Cía. Ltda.' +
    '</p>' +
    '<p>' +
    'Automekano Cía. Ltda. se reserva el derecho de hacer cambios en cualquier momento y sin previo aviso u obligación a la información contenida en este sitio de web; en cuanto a precios, programas de incentivos, especificaciones técnicas, equipamiento, ilustraciones, etc. Así como de modificar o suspender modelos y otros. Las imágenes y videos pueden no corresponder con el producto final. Algunas características del vehículo de la imagen no aplican a los modelos ensamblados o comercializados en Ecuador.' +
    '</p>',
}

const LABEL_INPUT_ID = {
  IDENTIFICATION: 'identification',
  NAME: 'name',
  EMAIL: 'email',
  PHONE: 'phone',
  DEALER: 'dealer',
  DATE: 'date',
  BRAND: 'brand',
  COMMENTS: 'comments',
  QUOTE: 'quote',
  DATA_SHEET: 'dataSheet',
}

const LABEL_SERVICES = {
  PARTS: 'repuestos',
  SERVICE: 'servicioTecnico',
  SALES: 'venta',
}

const LABEL_TEXT = {
  TEXT_SHARE: 'Mira esto te puede interesar',
  TEXT_SIRENA_APP: 'Hola Automekano! Estoy interesad@ en el modelo',
}

const LABEL_SUBTYPE = {
  'camioneta': {
    name: 'Camioneta',
    image: {
      generic: Camioneta,
      'great-wall': PickupGreatWall
    },
  },
  'mini-camioneta': {
    name: 'Mini Camioneta',
    image: {
      generic: MiniCamioneta,
      dfsk: MiniVanDfsk
    },
  },
  'suv': {
    name: 'SUV',
    image: {
      generic: Suv,
      soueast: SuvSoueast,
      dfsk: SuvDfsk
    },
  },
  'sedan': {
    name: 'Sedán',
    image: {
      generic: Sedan,
      soueast: SedanSoueast
    },
  },
  'furgonetas-escolares': {
    name: 'Furgonetas escolares',
    image: {
      generic: FurgonetasEscolares,
      'king-long': VanKingLong
    },
  },
  'furgonetas': {
    name: 'Furgonetas',
    image: {
      generic: Furgonetas,
      dfsk: VanDfsk
    },
  },
  'camiones': {
    name: 'Camiones',
    image: {
      generic: Camiones,
      'ud-trucks': TruckUdTrucks
    },
  },
  'volquetas': {
    name: 'Volquetas',
    image: {
      generic: Volquetas,
      'ud-trucks': DumpTruckUdTrucks
    },
  },
  'cabezales': {
    name: 'Cabezales',
    image: {
      generic: Cabezales,
      'ud-trucks': HeadsUdTrucks
    },
  },
  'retroexcavadoras': {
    name: 'Retroexcavadoras',
    image: {
      generic: Retroexcavadoras,
      jcb: BackhoesJcb
    },
  },
  'excavadoras': {
    name: 'Excavadoras',
    image: {
      generic: Excavadoras,
      jcb: ExcavatorsJcb
    },
  },
  'mini-excavadoras': {
    name: 'Mini Excavadoras',
    image: {
      generic: ''
    },
  },
  'compactadores': {
    name: 'Compactadores',
    image: {
      generic: Compactadores,
      jcb: CompactorsJcb
    },
  },
  'cargadoras-frontales': {
    name: 'Cargadoras Frontales',
    image: {
      generic: ''
    },
  },
  'montacargas': {
    name: 'Montacargas',
    image: {
      generic: Montacargas
    },
  },
  'manipuladores-telescopicos': {
    name: 'Manipuladores Telescópicos',
    image: {
      generic: ''
    },
  },
  'mini-cargadores': {
    name: 'Mini Cargadores',
    image: {
      generic: MiniCargadores
    },
  },
  'electrico': {
    name: 'Eléctrico',
    image: {
      generic: Electrico,
      dfsk: ElectricDfsk
    },
  },
}

const FROM_TYPE_ID = {
  QUOTE: 'QUOTE',
  OFFER: 'OFFER',
  DATA_SHEET: 'DATA_SHEET',
  CONTACT: 'CONTACT',
} as const

export type TFormTypeId = typeof FROM_TYPE_ID[keyof typeof FROM_TYPE_ID]

const FORM_TYPE = {
  [FROM_TYPE_ID.QUOTE]: 'Cotizador',
  [FROM_TYPE_ID.OFFER]: 'Oferta',
  [FROM_TYPE_ID.DATA_SHEET]: 'Ficha Técnica',
}

const SIRENA_TYPE_SOURCE = {
  QUOTE: 'Web cotizadores',
  OFFER: 'Web ofertas',
}

const TYPE_DEALER = {
  AUTOMEKANO: 'automekano',
  AMBACAR: 'ambacar',
  EXTERNO: 'externo',
}

const TYPE_MODEL = {
  LIGHT: 'livianos',
  HEAVY: 'pesados',
}

const DATA_LAYER = {
  WHATSAPP: 'Whatsapp',
}

const BRANDS = {
  SOUEAST: 'soueast',
  UD_TRUCKS: 'ud-trucks',
  JCB: 'jcb',
  KING_LONG: 'king-long',
  DFSK: 'dfsk',
  WEICHAI: 'weichai'
}

const ALLOWED_TAGS = {
  DATA_SHEET: ['b', 'i', 'em', 'strong', 'a', 'ul', 'li'],
  DATA_SCHEMA: ['']
}

export {
  LABEL_ERROR,
  LABEL_TEXT,
  LABEL_INPUT_ID,
  LABEL_INPUT,
  LABEL_SUBTYPE,
  FROM_TYPE_ID,
  FORM_TYPE,
  SIRENA_TYPE_SOURCE,
  TYPE_DEALER,
  LABEL_SERVICES,
  TYPE_MODEL,
  DATA_LAYER,
  BRANDS,
  ALLOWED_TAGS,
}
