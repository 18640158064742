import { LocalStorage } from '../types/components'
import { KEY_DATA_USER_LS } from '../constants/constantsApp'

export const getLocalStorage = key => {
  if (typeof localStorage != 'undefined') {
    return JSON.parse(localStorage.getItem(key))
  }
  return {}
}

export const setLocalStorage = (key, data) => {
  return window.localStorage.setItem(key, JSON.stringify(data))
}

export const getSessionStorage = key => {
  if (typeof sessionStorage != 'undefined') {
    return JSON.parse(sessionStorage.getItem(key))
  }
  return {}
}

export const setSessionStorage = (key, data) => {
  return window.sessionStorage.setItem(key, JSON.stringify(data))
}

export const saveDataLeadLocalStorage = (
  identification: string,
  name: string,
  email: string,
  phone: string
) => {
  const keyLocalStorage = KEY_DATA_USER_LS

  const data: LocalStorage[] = !getLocalStorage(keyLocalStorage)
    ? []
    : getLocalStorage(keyLocalStorage)
  const dataValidate = data.find(
    (item) => item.identification === identification
  )

  if (!dataValidate) {
    data.push({
      identification,
      name,
      email,
      phone,
    })
    setLocalStorage(keyLocalStorage, data)
  }
}

export const loadDataLeadLocalStorage = identification => {
  const data: LocalStorage[] = getLocalStorage(KEY_DATA_USER_LS)
  return !!data && data.find((item) => item.identification === identification)
}
