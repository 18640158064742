/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// execute when gatstby execute in client

require('./src/sass/global.scss')

const React = require('react')
const queryString = require('query-string')
const localStorage = require('./src/utils/localStorage')
const constantsApp = require('./src/constants/constantsApp')
const { siteUrlLocal, siteUrlProd } = require('./config')

require("@fontsource/montserrat/100.css")
require("@fontsource/montserrat/200.css")
require("@fontsource/montserrat/300.css")
require("@fontsource/montserrat/400.css")
require("@fontsource/montserrat/500.css")
require("@fontsource/montserrat/600.css")
require("@fontsource/montserrat/700.css")

// check change App
exports.onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    `El sitio ha sido actualizado. Deseas cargar todos los nuevos cambios? `
  )
  if (answer === true) {
    window.location.reload()
  }
}

// Add promote install App
let deferredPrompt
window.addEventListener('beforeinstallprompt', e => {
  // Prevent the mini-infobar from appearing on mobile
  e.preventDefault()
  // Stash the event so it can be triggered later.
  deferredPrompt = e
  // Update UI notify the user they can install the PWA
  // eslint-disable-next-line no-undef
  // showInstallPromotion()
})

exports.onClientEntry = () => {
  window.onload = () => {
    const { location, document } = window
    const referrer = document.referrer
    const queryStringSearch = location.search
    const { utm_source, utm_medium, utm_campaign } = queryString.parse(
      queryStringSearch
    )
    const isInternalDomain =
      referrer.includes(siteUrlLocal) || referrer.includes(siteUrlProd)
    const utmData = {
      firstReferrer: isInternalDomain ? '' : referrer,
      utmSource: utm_source || '',
      utmMedium: utm_medium || '',
      utmCampaign: utm_campaign || '',
    }
    localStorage.setSessionStorage(constantsApp.KEY_UTM_LS, utmData)
  }
}
