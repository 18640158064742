import { escapeRegExp } from './stringUtils'
import { isEmpty } from './validationUtils'

export const keepOnlyNumberCharacters = (val = '', decimalSeparator = '.') => {
  const numRegex = new RegExp(`\\\\d|${escapeRegExp(decimalSeparator)}`, 'g')
  return (val.match(numRegex) || []).join('').replace(decimalSeparator, '.')
}

export const splitDecimal = (number: string) => {
  const parts = number.split('.')
  const integerPart = parts[0]
  const fractionalPart = parts[1] || ''
  return {
    integerPart,
    fractionalPart,
  }
}

export const limitLength = (number: string, length: number) => {
  let str = ''
  for (let i = 0; i <= length - 1; i = i + 1) {
    str += number[i] || ''
  }
  return str
}

export const formatDecimalNumber = (
  num = '',
  maxDecimals = 2,
  decimalSeparator = '.'
) => {
  if (isEmpty(num)) return num

  let float = String(keepOnlyNumberCharacters(num, decimalSeparator))

  if (decimalSeparator && maxDecimals === 0) {
    float = float.split(decimalSeparator)[0]
  }

  float = float.charAt(0) === '.' ? `0.${float.substring(1)}` : float

  const removeExtraDecimalSeparators = float.split('.')
  float =
    removeExtraDecimalSeparators.length > 1
      ? `${removeExtraDecimalSeparators.shift()}.${removeExtraDecimalSeparators.join(
          ''
        )}`
      : float

  // tslint:disable-next-line: prefer-const
  let { integerPart, fractionalPart } = splitDecimal(float)

  if (maxDecimals !== undefined) {
    fractionalPart = limitLength(fractionalPart, maxDecimals)
  }

  const hasDecimalSeparator = float.indexOf('.') !== -1

  return (
    integerPart +
    ((hasDecimalSeparator && decimalSeparator) || '') +
    fractionalPart
  )
}

export const formatCharactersHTMLtoString = (string: string) => {
  if (isEmpty(string)) return string
  const regex = /(\&\#[1-9]*1?\;)/gi
  const numberPattern = /\d+/g

  const regExpMatchArray = string.match(regex)
  if (!regExpMatchArray) return string

  const numberCharacter = regExpMatchArray.join()
  const characterHtml = String.fromCharCode(
    parseInt(numberCharacter.match(numberPattern).join(), 10)
  )

  return string.replace(numberCharacter, characterHtml)
}

export const formatStringToFloat = (string: string, maxDecimals = 2) => {
  return parseFloat(parseFloat(string).toFixed(maxDecimals))
}

export const randomNumber = (min = 1, max = 100) => {
  return min + Math.random() * (max - min)
}

export const fixDecimals = (number: number, maxDecimals = 2) => {
  return number.toFixed(maxDecimals)
}
