import { validateDocument } from '../utils/validateIdentificacion'
import { DEMO_ENV, ENVIRONMENT, PRODUCTION_ENV } from './environmentConstants'
import { randomNumber } from '../utils/numberUtils'
import { FROM_TYPE_ID, TYPE_MODEL } from './constantsLabel'
import config from '../../config'

const CONFIG = {
  ACTIVATE_BLOG: true,
  RELATED_MODELS: true,
  GENERAL_CONFIG: {
    terms: [
      { id: 1, value: '12', label: '12' },
      { id: 2, value: '24', label: '24' },
      { id: 3, value: '36', label: '36' },
      { id: 4, value: '48', label: '48' },
      { id: 5, value: '60', label: '60' },
    ],
    monthlyPayments: [
      { id: 1, value: 1.09467652, label: '12' },
      { id: 2, value: 1.18735304, label: '24' },
      { id: 3, value: 1.28532956, label: '36' },
      { id: 4, value: 1.38770608, label: '48' },
      { id: 5, value: 1.49432765, label: '60' },
    ],
    buyIntentions: [
      { id: 1, value: '1', label: '1' },
      { id: 2, value: '2', label: '2' },
      { id: 3, value: '3', label: '3' },
      { id: 4, value: '+3', label: '+3' },
    ],
    monthlyFee: {
      '12': 1.09467652,
      '24': 1.18735304,
      '36': 1.28532956,
      '48': 1.38770608,
      '60': 1.49432765,
    },
    exonerated: [
      { id: '60', value: '30-49', label: '30% - 49%' },
      { id: '70', value: '50-74', label: '50% - 74%' },
      { id: '80', value: '75-84', label: '75% - 84%' },
      { id: '100', value: '85-100', label: '85% - 100%' },
    ],
    defaultCity: 'Guayaquil',
    validateIdentificationDocument: validateDocument,
    entryAmount: 0.3,
    code_country: '+593',
    id_country: 'EC',
    wpApiAuthentication: {
      PROD:
        'Basic QGFwcC1hdXRvbWVrYW5vOmkwWE0gYkxYdiBVN1RKIFNXeXkgNVJmMiBSa2RW',
      STAGING:
        'Basic QGFwcC1hdXRvbWVrYW5vOmkwWE0gYkxYdiBVN1RKIFNXeXkgNVJmMiBSa2RW', // 'Basic QGFwcC1hdXRvbWVrYW5vOk9oU3YgeGpLVyBQcEhIIDVCa1ogV3VsdyBTY3N3', TODO remove it
      DEMO:
        'Basic QGFwcC1hdXRvbWVrYW5vOmkwWE0gYkxYdiBVN1RKIFNXeXkgNVJmMiBSa2RW', // 'Basic QGFwcC1hdXRvbWVrYW5vOk9oU3YgeGpLVyBQcEhIIDVCa1ogV3VsdyBTY3N3', TODO remove it
    },
    userGuaguamedia: 'PRGU',
    userGuaguamediaAutomekano: 'PRGA',
    prefixEndpoint: '/wp-json/wp/v2',
    prefixEndpointEmail: '/wp-json/email/v1',
    prefixEndpointContingency: '/wp-json/save/v1',
    integrator: 'https://automation.automekano.com',
    tokenZeus:
      '888B19A43B151683C87895F6211D9F8640F97BDC8EF32F03DBE057C8F5E56D32',
    prefixZeisEndpoint: '/IBS3ProspeccionWS/tropidatos',
    urlSiteAutomekano: config.siteUrlProd,
    urlEndPointSirenaApp: 'https://api.getsirena.com/v1/lead/',
    phoneSirenaApp: {
      [TYPE_MODEL.LIGHT]: '967650100',
      [TYPE_MODEL.HEAVY]: '967650200',
    },
    apiKeySirenaApp: 'mPbrcQW4YsZyRf4dUEmndMCujikh3oRT',
    urlZeusAutomekano: {
      PROD: 'https://zeus.automekano.com:2083',
      STAGING: 'https://zeus.automekano.com:2083',
      DEMO: 'https://zeus.automekano.com:2083',
    },
    urlZeusAmbacar: {
      PROD: 'https://zeus.ambacar.com:2053',
      STAGING: 'https://zeus.ambacar.com:2053',
      DEMO: 'https://zeus.ambacar.com:2053',
    },
    urlAutomekano: {
      PROD: 'https://headless.automekano.com',
      STAGING: 'https://headless.automekano.com',
      DEMO: 'https://headless.automekano.com',
    },
    orderCities: ['Guayaquil', 'Quito', 'Ambato', 'Cuenca'],
    contactEmail: 'cotizador@automekano.com',
    subOriginPilot: {
      [FROM_TYPE_ID.QUOTE]: "FJ88BBACW6FD59DTS",
      [FROM_TYPE_ID.OFFER]: "FJ88KVAOWXY3SVXMO",
      EXONERATED: "FJ88KFTY6GTSTCNPC",
    }
  },
}

let ZEUS_URL_AUTOMEKANO = ''
let ZEUS_URL_AMBACAR = ''
let BASIC_AUTHENTICATION = ''
let AUTOMEKANO_URL = ''
let AUTOMEKANO_INTEGRATOR = ''
switch (ENVIRONMENT) {
  case PRODUCTION_ENV:
    AUTOMEKANO_URL = CONFIG.GENERAL_CONFIG.urlAutomekano.PROD
    ZEUS_URL_AUTOMEKANO = CONFIG.GENERAL_CONFIG.urlZeusAutomekano.PROD
    ZEUS_URL_AMBACAR = CONFIG.GENERAL_CONFIG.urlZeusAmbacar.PROD
    BASIC_AUTHENTICATION = CONFIG.GENERAL_CONFIG.wpApiAuthentication.PROD
    AUTOMEKANO_INTEGRATOR = `${CONFIG.GENERAL_CONFIG.integrator}/webhook`
    break
  case DEMO_ENV:
    AUTOMEKANO_URL = CONFIG.GENERAL_CONFIG.urlAutomekano.DEMO
    ZEUS_URL_AUTOMEKANO = CONFIG.GENERAL_CONFIG.urlZeusAutomekano.DEMO
    ZEUS_URL_AMBACAR = CONFIG.GENERAL_CONFIG.urlZeusAmbacar.DEMO
    BASIC_AUTHENTICATION = CONFIG.GENERAL_CONFIG.wpApiAuthentication.DEMO
    AUTOMEKANO_INTEGRATOR = `${CONFIG.GENERAL_CONFIG.integrator}/webhook-test`
    break
  default:
    AUTOMEKANO_URL = CONFIG.GENERAL_CONFIG.urlAutomekano.DEMO
    ZEUS_URL_AUTOMEKANO = CONFIG.GENERAL_CONFIG.urlZeusAutomekano.DEMO
    ZEUS_URL_AMBACAR = CONFIG.GENERAL_CONFIG.urlZeusAmbacar.DEMO
    BASIC_AUTHENTICATION = CONFIG.GENERAL_CONFIG.wpApiAuthentication.DEMO
    AUTOMEKANO_INTEGRATOR = `${CONFIG.GENERAL_CONFIG.integrator}/webhook-test`
}

export const AUTOMEKANO_INTEGRATOR_URL = AUTOMEKANO_INTEGRATOR
export const AUTOMEKANO_HEADLESS_URL = AUTOMEKANO_URL
export const AUTOMEKANO_SITE_URL = CONFIG.GENERAL_CONFIG.urlSiteAutomekano
export const AUTOMEKANO_ENDPOINT_EMAIL = `${AUTOMEKANO_URL}${CONFIG.GENERAL_CONFIG.prefixEndpointEmail}`
export const AUTOMEKANO_ENDPOINT_CONTINGENCY = `${AUTOMEKANO_URL}${CONFIG.GENERAL_CONFIG.prefixEndpointContingency}`
export const SIRENA_APP_ENDPOINT = CONFIG.GENERAL_CONFIG.urlEndPointSirenaApp
export const SIRENA_APP_API_KEY = CONFIG.GENERAL_CONFIG.apiKeySirenaApp
export const SIRENA_APP_WHATSAPP = CONFIG.GENERAL_CONFIG.phoneSirenaApp
export const POST_LEAD_ZEUS_AUTOMEKANO = `${ZEUS_URL_AUTOMEKANO}${CONFIG.GENERAL_CONFIG.prefixZeisEndpoint}`
export const POST_LEAD_ZEUS_AMBACAR = `${ZEUS_URL_AMBACAR}${CONFIG.GENERAL_CONFIG.prefixZeisEndpoint}`
export const EMAIL_CONTACT = CONFIG.GENERAL_CONFIG.contactEmail
export const USER_GUAGUAMEDIA = `${CONFIG.GENERAL_CONFIG.userGuaguamedia}`
export const USER_GUAGUAMEDIA_AUTOMEKANO = `${CONFIG.GENERAL_CONFIG.userGuaguamediaAutomekano}`
export const TOKEN_ZEUS = `${CONFIG.GENERAL_CONFIG.tokenZeus}`
export const DATA_EXONERATED = CONFIG.GENERAL_CONFIG.exonerated
export const AUTHENTICATION_EMAIL = BASIC_AUTHENTICATION
export const AUTOMEKANO_CODE = 'K'
export const AMBACAR_CODE = 'A'
export const TYPE_QUOTE_CODE = 'CO'
export const TYPE_OFFER_CODE = 'CO'
export const TYPE_DATA_SHEET_CODE = 'CO'
export const LEAD_NUMBER = randomNumber(1, 10000)
export const QUOTE_CODE = 'VNT'
export const OFFER_CODE = 'VNT'
export const DATA_SHEET_CODE = 'VNT'
export const CODE_ZEUS_SOURCE_WEB = 'PGW'
export const CODE_SOURCE_WEB = 'web'
export const CODE_TYPE_UTM = 'utm'
export const CODE_ZEUS_SOURCE_WEB_AUTOMEKANO_FOR_AMBACAR = 'PWA'
export const TEMPLATE_QUOTE = 'cotizar'
export const TEMPLATE_DEALER_QUOTE = 'cotizar-dealer'
export const TEMPLATE_CONTACT = 'contacto'
export const TEMPLATE_DATA_SHEET = 'ficha-tecnica'
export const TEMPLATE_OFFER = 'oferta'
export const TEMPLATE_SUBJECT_QUOTE = 'Cotizador - Automekano'
export const TEMPLATE_SUBJECT_CONTACT = 'Contacto - Automekano'
export const TEMPLATE_SUBJECT_DATA_SHEET = 'Ficha Técnica - Automekano'
export const ORDER_CITIES = CONFIG.GENERAL_CONFIG.orderCities
export const DEFAULT_ZOOM = 8
export const CODE_AUTOMOBILE = 3
export const DEFAULT_MOBILE_ZOOM = 7
export const KEY_GOOGLE_MAP = 'AIzaSyDJawf8X6lkkESJw4Sn9ajaunDzzToWz24'
export const KEY_DATA_USER_LS = 'dataUser'
export const KEY_UTM_LS = 'utmData'
export const SUB_ORIGIN_PILOT = CONFIG.GENERAL_CONFIG.subOriginPilot

export { CONFIG }
